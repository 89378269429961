import {useDispatch} from 'react-redux'
import {useIntl} from 'react-intl'
import MultiFactorAuthModal from './MultiFactorAuthModal'
import {toast} from 'react-toastify'
import {
  generateMfaOtp,
  verifyMfaOtp,
} from 'setup/redux/Actions/UserSecurityDetails/userSecurityDetails'
import {login} from 'setup/redux/Actions/SignIn/login'
import {useEffect, useState} from 'react'

interface UserDetails {
  userEmail: string
  userId: string
  userPassword: string
}

interface MultiFactorAuthProps {
  showModal: boolean
  closeModal: () => void
  userDetails: UserDetails
  onModalError: (message: string) => void
}

const MultiFactorAuth = ({
  showModal,
  closeModal,
  userDetails,
  onModalError,
}: MultiFactorAuthProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(true)

  const generateOtp = () => {
    const payload = {
      email: userDetails?.userEmail,
      userId: userDetails?.userId,
    }

    const onSuccess = (res: any) => {
      setIsLoading(false)
      toast.success(intl.formatMessage({id: 'OTP_CREATE.MESSAGE'}))
    }
    const onFailure = (res: any) => {
      setIsLoading(false)
      onModalError(res?.message)
    }

    dispatch(generateMfaOtp(payload, onSuccess, onFailure))
  }

  useEffect(() => {
    generateOtp()
  }, [])

  const handleSubmit = (otp: string) => {
    const payload = {
      email: userDetails?.userEmail,
      userId: userDetails?.userId,
      otp,
    }

    const onSuccess = (res: any) => {
      setIsLoading(true)
      dispatch(
        login(
          userDetails?.userEmail,
          userDetails?.userPassword,
          (res) => {
            setIsLoading(false)
          },
          (res) => {
            setIsLoading(false)
            toast.error(res?.message ?? 'Invalid username or password')
          }
        )
      )
    }
    const onFailure = (res: any) => {
      if (res.statusCode === 400) {
        toast.error(res?.message || intl.formatMessage({id: 'GENERAL.SOMETHING_WENT_WRONG'}))
      } else {
        onModalError(res?.message)
      }
    }
    dispatch(verifyMfaOtp(payload, onSuccess, onFailure))
  }

  if (isLoading) {
    return (
      <div className='d-flex justify-content-center'>
        <div className='spinner-grow text-primary' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
      </div>
    )
  }

  return (
    <>
      <MultiFactorAuthModal
        show={showModal}
        onHide={() => closeModal()}
        userDetails={userDetails}
        generateOtp={generateOtp}
        verifyOtp={handleSubmit}
      />
    </>
  )
}

export default MultiFactorAuth
