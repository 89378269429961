import {getBaseUrl, getTenantDomain} from 'common/HelperFunctions'
import {io} from 'socket.io-client'

const socket = io(getBaseUrl(), {
  query: {
    domain: getTenantDomain(),
  },
  transports: ['websocket'],
  autoConnect: true,
})

socket.on('connect', () => {
  console.log(`✅ Connected to socket: ${socket.id}`)
})

socket.on('disconnect', () => {
  console.log(`❌ Disconnected from socket`)
})

socket.on('connect_error', (error) => {
  console.error(`❌ Connection error: ${error.message}`)
})

export default socket
