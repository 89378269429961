import {
  LOGIN_USER,
  LOGIN_USER_JWT,
  USER_ME,
  VERIFY_LOGIN_CREDENTIALS,
} from './ActionTypes/ApiActionTypes'
import Constants from 'common/constants'
import {REDUX_SAVE_LOGIN_INFO} from './ActionTypes/ReduxActionTypes'

/**
 *
 * @param {string} username
 * @param {string} password
 * @param {function(response)} onSuccess
 * @param {function(response)} onFailure
 * @returns
 */
export const login = (username, password, onSuccess, onFailure) => {
  return {
    type: LOGIN_USER,
    payload: {
      onSuccess: onSuccess,
      onFailure: onFailure,
      apiUrl: Constants.api_login,
      noAccessToken: true,
      getString: '',
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      body: {
        username: username,
        password: password,
      },
      reduxActionType: REDUX_SAVE_LOGIN_INFO,
    },
  }
}

/**
 *
 * @param {string} authToken
 * @param {(res) => void} onSuccess
 * @param {(res) => void} onFailure
 * @returns
 */
export const loginWithJWT = (authToken, onSuccess, onFailure) => {
  return {
    type: LOGIN_USER_JWT,
    payload: {
      onSuccess,
      onFailure,
      apiUrl: Constants.api_login_jwt,
      noAccessToken: true,
      getString: '',
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      body: {
        authToken,
      },
      reduxActionType: REDUX_SAVE_LOGIN_INFO,
    },
  }
}

/**
 *
 * @param {(res) => void} onSuccess
 * @param {(res) => void} onFailure
 * @returns
 */
export const getLoggedInUserData = (onSuccess, onFailure) => {
  return {
    type: USER_ME,
    payload: {
      onSuccess,
      onFailure,
      apiUrl: Constants.api_user_me,
      getString: '',
      useBaseURL: true,
      requestType: 'GET',
      header: 'application/json',
      reduxActionType: REDUX_SAVE_LOGIN_INFO,
    },
  }
}

/**
 *
 * @param {string} username
 * @param {string} password
 * @param {function(response)} onSuccess
 * @param {function(response)} onFailure
 * @returns
 */
export const verifyLoginCredentials = (username, password, onSuccess, onFailure) => {
  return {
    type: VERIFY_LOGIN_CREDENTIALS,
    payload: {
      onSuccess: onSuccess,
      onFailure: onFailure,
      apiUrl: 'user/verify-login-credentials',
      noAccessToken: true,
      getString: '',
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      body: {
        username: username,
        password: password,
      },
      reduxActionType: '',
    },
  }
}
