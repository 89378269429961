import { createContext, useContext, useEffect, useState } from 'react';
import socket from '../../socket';

const SocketContext = createContext<{
  isConnected: boolean;
  joinRoom: (roomName: string) => void;
  leaveRoom: (roomName: string) => void;
  sendMessage: (event: any, data: any) => void;
}>(null!);

export const SocketProvider = ({ children }:any) => {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [connectionStatus, setConnectionStatus] = useState(socket.connected);

  useEffect(() => {
    const onConnect = () => {
      setIsConnected(true);
      setConnectionStatus(true);
    };
    const onDisconnect = () => {
      setIsConnected(false);
      setConnectionStatus(false);
    };

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);

    const checkConnectionStatus = () => {
      const status = socket.connected;
      setConnectionStatus(status);
    };
    const intervalId = setInterval(checkConnectionStatus, 5000);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      clearInterval(intervalId);
    };
  }, []);

  const joinRoom = (roomName:string) => {
    socket.emit('join-room', { room: roomName });
  };

  const leaveRoom = (roomName:string) => {
    socket.emit('leave-room', { room: roomName });
  };

  const sendMessage = (event:any, data:any) => {
    socket.emit(event, data);
  };

  return (
    <SocketContext.Provider value={{ isConnected, joinRoom, leaveRoom, sendMessage }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => {
    const context = useContext(SocketContext);
    if (!context) {
      throw new Error('useSocket must be used within a SocketProvider');
    }
    return context;
  };
  