import Modal from 'react-bootstrap/Modal'
import {maskEmail} from 'common/HelperFunctions'
import React, {useState, useRef, useEffect} from 'react'
import {Form, Button, Row, Col, Image} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

interface MultiFactorAuthModalProps {
  show: boolean
  onHide: () => void
  userDetails: {userEmail: string}
  generateOtp: () => void
  verifyOtp: (otp: string) => void
}

function MultiFactorAuthModal({
  show,
  onHide,
  userDetails,
  generateOtp,
  verifyOtp,
}: MultiFactorAuthModalProps) {
  const intl = useIntl()
  const length: number = 6
  const [otp, setOtp] = useState<string[]>(Array(length).fill(''))
  const inputRefs = useRef<(HTMLInputElement | null)[]>(Array(length).fill(null))

  const newOtpCooldown = 60
  const [countdown, setCountdown] = useState(newOtpCooldown)
  const [disabled, setDisabled] = useState(true)

  const handleChange = (index: number, value: string) => {
    if (!/^[0-9]?$/.test(value)) return
    const newOtp = [...otp]
    newOtp[index] = value
    setOtp(newOtp)

    if (value && index < length - 1) {
      inputRefs.current[index + 1]?.focus()
    }
  }

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace') {
      const newOtp = [...otp]

      newOtp[index] = ''
      setOtp(newOtp)

      if (index > 0 && !otp[index]) {
        inputRefs.current[index - 1]?.focus()
      }
    }
  }

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    const pasteData = e.clipboardData.getData('text').trim()
    if (!/^\d{6}$/.test(pasteData)) return

    const newOtp = pasteData.split('')
    setOtp(newOtp)

    inputRefs.current[length - 1]?.focus()
  }

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000)
      return () => clearTimeout(timer)
    } else {
      setDisabled(false)
    }
  }, [countdown])

  const handleClick = () => {
    setDisabled(true)
    setCountdown(newOtpCooldown)
    generateOtp()
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (otp.every((digit) => digit !== '')) {
      verifyOtp(otp.join(''))
    } else {
      toast.error(intl.formatMessage({id: 'EMAIL_OTP.OTP_ERROR'}))
    }
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          {intl.formatMessage({id: 'MFA_HEADING'})}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex flex-column align-items-center p-4'>
          <div className='text-center mb-4'>
            <Image
              src='/media/icons/duotune/general/authentication.png'
              alt='Phone'
              className='mb-2'
              style={{height: '100px'}}
            />
            <p className='text-muted mt-8'>{intl.formatMessage({id: 'MFA_OTP.MESSAGE'})}</p>
            <p className='fw-bold text-dark fs-5'>
              <b>{maskEmail(userDetails.userEmail)}</b>
            </p>
          </div>
          <Form onSubmit={handleSubmit} className='w-100' style={{maxWidth: '300px'}}>
            <Row className='g-2 justify-content-center mb-4'>
              {otp.map((digit, index) => (
                <Col key={index} xs={2}>
                  <Form.Control
                    type='text'
                    maxLength={1}
                    value={digit}
                    onChange={(e) => handleChange(index, e.target.value)}
                    onKeyDown={(e: any) => handleKeyDown(index, e)}
                    onPaste={handlePaste}
                    ref={(ref: any) => (inputRefs.current[index] = ref)}
                    className='text-center fw-bold fs-5'
                  />
                </Col>
              ))}
            </Row>
            <Button type='submit' className='w-100 btn btn-primary mt-6'>
              {intl.formatMessage({id: 'GENERAL.SUBMIT'})}
            </Button>

            <span
              onClick={handleClick}
              className={`fw-bold ${disabled ? 'text-muted' : 'text-primary'} 
              ${disabled ? '' : 'cursor-pointer'} d-block mt-4 text-center`}
              style={{
                pointerEvents: disabled ? 'none' : 'auto',
                cursor: disabled ? 'not-allowed' : 'pointer',
              }}
            >
              {disabled ? `Request New OTP (${countdown}s)` : 'Request New OTP'}
            </span>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default MultiFactorAuthModal
