export const GET_TIMETABLE_CATEGORIES = 'GET_TIMETABLE_CATEGORIES'
export const GET_TIMETABLE_SUBCATEGORIES = 'GET_TIMETABLE_SUBCATEGORIES'
export const GET_TIMETABLE_REPORT = 'GET_TIMETABLE_REPORT'
export const GET_TIMETABLE_SUBCATEGORY_BY_ID = 'GET_TIMETABLE_SUBCATEGORY_BY_ID'
export const ADD_CONSTRAINT_BY_ID = 'ADD_CONSTRAINT_BY_ID'
export const GET_ALL_CONSTRAINTS = 'GET_ALL_CONSTRAINTS'
export const DELETE_CONSTRAINT = 'DELETE_CONSTRAINT'
export const GET_CONSTRAINT_BY_ID = 'GET_CONSTRAINT_BY_ID'
export const UPDATE_CONSTRAINT = 'UPDATE_CONSTRAINT'
export const GET_TIMETABLE = 'GET_TIMETABLE'
export const REQUEST_TIMETABLE = 'REQUEST_TIMETABLE'
export const DELETE_TIMETABLE = 'DELETE_TIMETABLE'
