/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {verifyLoginCredentials, login} from 'setup/redux/Actions/SignIn/login'
import {USER_TYPES} from 'common/constants'
import {getTenantCustomDomain} from 'common/HelperFunctions'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {toast} from 'react-toastify'
import MultiFactorAuth from '../modals/MultiFactorAuth'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    // .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email/Username is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

const userDetailInitialValue = {
  userType: '',
  userEmail: '',
  userId: '',
  userPassword: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showMfaModal, setShowMfaModal] = useState(false)
  const [userDetails, setUserDetails] = useState(userDetailInitialValue)
  const dispatch = useDispatch()

  const generalSettings = useSelector<any>(({mainReducer}) => mainReducer.generalSettings) as any

  function onModalError(message: string) {
    setShowMfaModal(false)
    toast.error(message)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setSubmitting(true)
      setStatus('')

      const onLoginFailure = (res: any) => {
        setStatus(res?.message ?? 'Invalid username or password')
        setLoading(false)
        setSubmitting(false)
      }

      setTimeout(() => {
        dispatch(
          verifyLoginCredentials(
            values.email,
            values.password,
            (res) => {
              const {userType, userEmailVerified} = res.result
              if ([USER_TYPES.ADMIN].includes(userType) && userEmailVerified) {
                setUserDetails({...res.result, userPassword: values.password})
                setShowMfaModal(true)
                setLoading(false)
                setSubmitting(false)
              } else {
                dispatch(
                  login(
                    values.email,
                    values.password,
                    (res) => {
                      const {userType, accessToken} = res.result
                      if ([USER_TYPES.STUDENT, USER_TYPES.PARENT].includes(userType)) {
                        window.location.replace(
                          `//${getTenantCustomDomain()}?rauth_token=${accessToken}`
                        )
                      } else {
                        setLoading(false)
                        setSubmitting(false)
                      }
                    },
                    (res) => onLoginFailure(res)
                  )
                )
              }
            },
            (res) => onLoginFailure(res)
          )
        )
      }, 1000)
    },
  })

  return (
    <>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {/* begin::Heading */}
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>
            Sign In {generalSettings?.tenantName ? `to ${generalSettings?.tenantName}` : ''}
          </h1>
        </div>
        {/* begin::Heading */}

        {formik.status ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        ) : (
          <div></div>
        )}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Email/Username</label>
          <input
            tabIndex={1}
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoFocus
            autoComplete='username'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2'>
              {/* begin::Label */}
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
              {/* end::Label */}
              {/* begin::Link */}
              <Link
                to='/auth/forgot-password'
                className='link-primary fs-6 fw-bolder'
                style={{marginLeft: '5px'}}
                tabIndex={4}
              >
                Forgot Password ?
              </Link>
              {/* end::Link */}
            </div>
          </div>
          <div className='position-relative'>
            <input
              tabIndex={2}
              type={showPassword ? 'text' : 'password'}
              autoComplete='current-password'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            <div
              className={clsx('position-absolute end-0 top-0 mt-5', {
                'me-5': !formik.touched.password,
                'me-12': formik.touched.password,
              })}
            >
              <OverlayTrigger
                placement='top'
                overlay={
                  <Tooltip id='tooltip-show-hide-password'>
                    {showPassword ? 'Hide' : 'Show'} Password
                  </Tooltip>
                }
              >
                <i
                  className={clsx('cursor-pointer fa', {
                    'fa-eye': !showPassword,
                    'fa-eye-slash': showPassword,
                  })}
                  onClick={() => setShowPassword(!showPassword)}
                  tabIndex={3}
                  aria-hidden='true'
                  aria-label='Show password'
                  role='button'
                />
              </OverlayTrigger>
            </div>
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Action */}
        <div className='text-center'>
          <button
            tabIndex={4}
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Continue</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>

        {generalSettings.admission_form_permission === 1 && (
          <div className='text-center'>
            <a
              id='kt_admission_form_redirect'
              href={`https://${getTenantCustomDomain()}/admission/login`}
              target='BLANK'
              className='pulsingButton'
            >
              {!loading && <span className='indicator-label'>ADMISSION FORM</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </a>
          </div>
        )}

        {/* end::Action */}
      </form>
      {showMfaModal && (
        <MultiFactorAuth
          showModal={showMfaModal}
          closeModal={() => setShowMfaModal(false)}
          userDetails={userDetails}
          onModalError={onModalError}
        />
      )}
    </>
  )
}
