import {} from 'setup/redux/Actions/Subject/ActionTypes/ApiActionTypes'
import {takeEvery} from 'redux-saga/effects'
import fetchData from '../SagaHelper'
import {
  GET_TIMETABLE_CATEGORIES,
  GET_TIMETABLE_SUBCATEGORIES,
  GET_TIMETABLE_SUBCATEGORY_BY_ID,
  ADD_CONSTRAINT_BY_ID,
  GET_ALL_CONSTRAINTS,
  DELETE_CONSTRAINT,
  GET_CONSTRAINT_BY_ID,
  UPDATE_CONSTRAINT,
  GET_TIMETABLE,
  DELETE_TIMETABLE,
  GET_TIMETABLE_REPORT,
} from 'setup/redux/Actions/Timetable/ActionTypes/ApiActionTypes'

function* dataSaga() {
  yield takeEvery(GET_TIMETABLE_CATEGORIES, fetchData)
  yield takeEvery(GET_TIMETABLE_SUBCATEGORIES, fetchData)
  yield takeEvery(GET_TIMETABLE_SUBCATEGORY_BY_ID, fetchData)
  yield takeEvery(ADD_CONSTRAINT_BY_ID, fetchData)
  yield takeEvery(GET_ALL_CONSTRAINTS, fetchData)
  yield takeEvery(DELETE_CONSTRAINT, fetchData)
  yield takeEvery(GET_CONSTRAINT_BY_ID, fetchData)
  yield takeEvery(UPDATE_CONSTRAINT, fetchData)
  yield takeEvery(GET_TIMETABLE, fetchData)
  yield takeEvery(DELETE_TIMETABLE, fetchData)
  yield takeEvery(GET_TIMETABLE_REPORT, fetchData)
}

export default dataSaga