import {
  GET_EMAIL_VERIFICATION_STATUS,
  GENERATE_EMAIL_OTP,
  VERIFY_EMAIL_OTP,
  GENERATE_MFA_OTP,
  VERIFY_MFA_OTP,
} from './ActionTypes/ApiActionTypes'

export const getEmailVerificationStatus = (body, onSuccess, onFailure) => {
  return {
    type: GET_EMAIL_VERIFICATION_STATUS,
    payload: {
      onSuccess: onSuccess,
      onFailure: onFailure,
      apiUrl: 'user-security-details/email-verification-status',
      getString: '',
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      body: body,
      reduxActionType: '',
    },
  }
}

export const generateEmailOtp = (body, onSuccess, onFailure) => {
  return {
    type: GENERATE_EMAIL_OTP,
    payload: {
      onSuccess: onSuccess,
      onFailure: onFailure,
      apiUrl: 'user-security-details/generate-email-otp',
      getString: '',
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      body: body,
      reduxActionType: '',
    },
  }
}

export const verifyEmailOtp = (body, onSuccess, onFailure) => {
  return {
    type: VERIFY_EMAIL_OTP,
    payload: {
      onSuccess: onSuccess,
      onFailure: onFailure,
      apiUrl: 'user-security-details/verify-email-otp',
      getString: '',
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      body: body,
      reduxActionType: '',
    },
  }
}

export const generateMfaOtp = (body, onSuccess, onFailure) => {
  return {
    type: GENERATE_MFA_OTP,
    payload: {
      onSuccess: onSuccess,
      onFailure: onFailure,
      apiUrl: 'user-security-details/generate-mfa-otp',
      getString: '',
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      body: body,
      reduxActionType: '',
    },
  }
}

export const verifyMfaOtp = (body, onSuccess, onFailure) => {
  return {
    type: VERIFY_MFA_OTP,
    payload: {
      onSuccess: onSuccess,
      onFailure: onFailure,
      apiUrl: 'user-security-details/verify-mfa-otp',
      getString: '',
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      body: body,
      reduxActionType: '',
    },
  }
}
