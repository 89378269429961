/* eslint-disable jsx-a11y/anchor-is-valid */
import {BUILD_VERSION} from 'common/constants'
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {useLayout} from '../core'
import {useSelector} from 'react-redux'
import {RootState} from 'setup'
import {hasPermission} from 'common/HelperFunctions'
import {Link} from 'react-router-dom'

const Footer: FC = () => {
  const {classes} = useLayout()
  const intl = useIntl()
  const generalSettings = useSelector<RootState>(
    ({mainReducer}) => mainReducer.generalSettings
  ) as any

  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Copyright */}
        <div className='text-dark order-2 order-md-1'>
          {!generalSettings?.powered_by_text && (
            <>
              <span className='text-muted fw-bold me-2'>{new Date().getFullYear()} &copy;</span>
              <a
                href='https://ilmversity.com'
                className='text-gray-800 text-hover-primary me-1'
                target='_blank'
                rel='noreferrer'
              >
                Ilmversity
              </a>
            </>
          )}
          {!!generalSettings?.powered_by_text && (
            <span
              className='me-1'
              dangerouslySetInnerHTML={{__html: generalSettings?.powered_by_text}}
            />
          )}
          | <Link to='/institute/change-log'>Build: {BUILD_VERSION}</Link>
          {/* <span className='badge bg-primary'>Beta </span> */}
        </div>
        {/* end::Copyright */}

        {/* begin::Nav */}
        {!generalSettings?.powered_by_text && (
          <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
            <li className='menu-item'>
              <a
                href='https://ilmversity.com/about-us/'
                target='_blank'
                className='menu-link ps-0 pe-2'
                rel='noreferrer'
              >
                About
              </a>
            </li>
            <li className='menu-item'>
              <a
                href='https://ilmversity.com/contact/'
                target='_blank'
                className='menu-link pe-2'
                rel='noreferrer'
              >
                Contact
              </a>
            </li>
            {hasPermission('knowledge_base') && (
              <li className='menu-item'>
                <a
                  href='https://ilmversity.com/knowledge-base/'
                  target='_blank'
                  className='menu-link pe-2 text-primary'
                  rel='noreferrer'
                >
                  <span className='fas fa-book-open me-1' />
                  {intl.formatMessage({id: 'MENU.KNOWLEDGE_BASE'})}
                </a>
              </li>
            )}
          </ul>
        )}

        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export {Footer}
