import {
  GET_TERMS,
  GET_TERM_SINGLE,
  ADD_TERM,
  UPDATE_TERM,
  DELETE_SINGLE_TERM,
  DELETE_MANY_TERMS,
  LOCK_UNLOCK_TERMS,
} from 'setup/redux/Actions/Term/ActionTypes/ApiActionTypes'
import {takeEvery} from 'redux-saga/effects'
import fetchData from '../../SagaHelper'

function* dataSaga() {
  yield takeEvery(GET_TERMS, fetchData)
  yield takeEvery(GET_TERM_SINGLE, fetchData)
  yield takeEvery(ADD_TERM, fetchData)
  yield takeEvery(UPDATE_TERM, fetchData)
  yield takeEvery(DELETE_SINGLE_TERM, fetchData)
  yield takeEvery(DELETE_MANY_TERMS, fetchData)
  yield takeEvery(LOCK_UNLOCK_TERMS, fetchData)
}

export default dataSaga
